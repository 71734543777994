<template>
  <div class="device-info">
    <el-drawer class="drawer" title="设备信息" direction="rtl" :visible.sync="visible">
      <el-form ref="form" :model="form" v-loading="loading">
        <el-form-item label="设备名称：">
          {{ form.device.name }}
        </el-form-item>
        <el-form-item label="运行状态：">
          {{ form.device.runningState }}
        </el-form-item>
        <el-form-item label="所属工厂：">
          {{ form.device.factoryName }}
        </el-form-item>
        <el-form-item label="所属车间：">
          {{ form.device.workShopName }}
        </el-form-item>
        <el-form-item label="所属产线：">
          {{ form.device.lineName }}
        </el-form-item>
        <div class="params">
          <h2 class="title">参数信息</h2>
          <Vtable :column="param_column" :data="form.params" />
        </div>
        <div class="warn">
          <h2 class="title">报警信息</h2>
          <Vtable :column="warning_column" :data="form.alarms" />
        </div>
      </el-form>
    </el-drawer>
  </div>
</template>

<script>
import Vtable from './table';
export default {
  data() {
    return {
      data: null,
      visible: false,
      loading: false,
      param_column: [
        {
          prop: 'paramName',
          label: '参数名称'
        },
        {
          prop: 'value',
          label: '参数值'
        }
      ],
      warning_column: [
        {
          prop: 'name',
          label: '报警内容'
        },
        {
          prop: 'exceptionTime',
          label: '报警时间'
        }
      ],
      form: {
        device: {},
        params: [],
        alarms: []
      }
    };
  },
  methods: {
    async loadData(deviceId) {
      this.visible = this.loading = true;

      this.data = await this.$apis.electron.deviceDetail({ deviceId });
      this.loading = false;
    }
  },
  watch: {
    data(val) {
      const map = {
        RUNNING_STATE: '运行',
        ALARM_STATE: '报警',
        FREE_STATE: '空闲',
        OFFLINE_STATE: '离线'
      };
      val.device.runningState = map[val.device.runningState];
      this.form = val;
    },
    visible(val) {
      if (!val) {
        this.form = {
          device: {},
          params: [],
          alarms: []
        };
      }
    }
  },
  components: {
    Vtable
  }
};
</script>

<style lang="scss" scoped>
@import '../style/index.scss';
.device-info {
  /deep/ .drawer {
    .el-drawer {
      width: 420px !important;
      padding-bottom: 20px;
      color: #fff;
      background: $secondary-color;
      .el-drawer__header {
        color: #fff;
        font-size: 18px;
      }
    }
    .el-form {
      padding: 0 20px;
      .el-form-item__label {
        color: #fff;
      }
    }

    .title {
      font-size: 16px;
      margin-bottom: 10px;
    }
    .warn {
      margin-top: 30px;
    }
  }
}
/deep/ .el-drawer__body::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
/deep/ .el-drawer__body::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
/deep/ .el-drawer__body {
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none; /* Firefox */
}
</style>
