<template>
  <div class="map">
    <header class="header clearfix">
      <div class="right">
        <MapCascader ref="mapCascader" :options="cascader_options" @update="changeMap"></MapCascader>
        <el-button type="primary" class="iconfont icon-dca-bianji1 edit" :disabled="enabledEdit" @click="edit">编辑</el-button>
        <el-button type="primary" class="iconfont icon-dcicon_play" :disabled="enabledPlay" @click="play">播放</el-button>
      </div>
    </header>
    <main class="main">
      <i class="iconfont icon-dcicon_jt_right_nor label" @click="showState"></i>
      <div id="graph"></div>
    </main>
    <section class="popper">
      <Device ref="device"></Device>
      <DeviceInfo ref="deviceInfo"></DeviceInfo>
    </section>
    <section class="routerview">
      <router-view></router-view>
    </section>
  </div>
</template>

<script>
import MapCascader from './components/cascader';
import Device from './components/drawer.vue';
import DeviceInfo from './components/info';
import generateMap from './utils/generateMap.js';
import deviceRunState from './utils/deviceRuningState';
import { base64ToUtf8 } from '@/common/js/base64';
export default {
  data() {
    return {
      graph: null,
      factory_list: [],
      factory: {
        id: '',
        mapId: ''
      },
      cascader_options: {
        options: [],
        props: {
          value: 'id',
          checkStrictly: 'true'
        }
      }
    };
  },
  created() {
    this.loadFactoryMapStructure();
  },
  methods: {
    // 加载工厂地图结构
    async loadFactoryMapStructure() {
      const factory_list = await this.$apis.electron.list();
      const notHaveFactory = factory_list.length === 0;

      // 如果没有工厂
      if (notHaveFactory) {
        this.$message.warning('还没有工厂，请先创建工厂');
      } else {
        this.factory_list = factory_list.map(factory => {
          return {
            label: factory.factoryName,
            id: factory.factoryId,
            children: factory.electronMapList.map(map => {
              return {
                ...map,
                label: map.name,
                id: map.electronMapId
              };
            })
          };
        });

        this.cascader_options.options = this.factory_list;
        this.initMap();
      }
    },
    // 初始化地图
    initMap() {
      this.$nextTick(() => {
        const dom = document.getElementById('graph');
        this.graph = generateMap(dom, {
          panning: true, // 启用画布平移
          keyboard: true, // 启用键盘快捷键
          // 节点的交互行为
          interacting: {
            nodeMovable: false // 节点是否可以被移动。
          },
          background: {
            color: '#121212'
          }
        });

        this.firstRender();
        this.bindGraphEvent();
      });
    },

    // 首次渲染地图
    firstRender() {
      // 在工厂列表里查找，含有地图的工厂
      const factory = this.factory_list.find(factory => factory.children.length);

      if (factory) {
        const map = factory.children[0];

        this.factory.id = factory.id;
        this.factory.mapId = map.id;
        this.$refs.mapCascader.value = [factory.id, this.factory.mapId];

        this.renderMap(map.mapContent);
      } else {
        const factory = this.factory_list[0];

        this.factory.id = factory.id;
        this.$refs.mapCascader.value = [factory.id];
      }
    },
    // 渲染地图
    renderMap(nodes) {
      if (nodes) {
        const mapContent = JSON.parse(base64ToUtf8(nodes));
        this.graph.fromJSON(mapContent);

        this.deviceRunState();
      } else {
        this.graph.clearCells();
      }
    },
    // 给设备添加设备状态
    deviceRunState() {
      const nodes = this.graph.getNodes();
      deviceRunState(nodes);
    },
    // 绑定 Graph 事件
    bindGraphEvent() {
      this.graph.on('node:click', async ({ node }) => {
        const nodaData = node.data;
        const deviceId = nodaData && nodaData.deviceId;

        if (deviceId) {
          this.$refs.deviceInfo.loadData(deviceId);
        }
      });
    },
    // 切换地图时
    async changeMap(val) {
      const isFactory = val.length === 1;
      const isMap = val.length === 2;
      const factoryId = val[0];

      if (isFactory) {
        this.factory.mapId = '';
        this.graph.clearCells();
      }

      if (isMap) {
        const mapId = val[1];
        const { mapContent } = await this.$apis.electron.electronDetails({ electronMapId: mapId });

        this.factory.mapId = mapId;
        this.renderMap(mapContent);
      }

      this.factory.id = factoryId;
    },
    // 显示设备状态
    showState() {
      this.$refs.device.changeVisible();
    },
    // 去编辑
    edit() {
      this.$router.push('/map/editor');
    },
    // 去播放
    play() {
      this.$router.push('/map/playsettings');
    },
    storageFactoryData() {
      localStorage.setItem('FactoryMap', JSON.stringify(this.factory));
    }
  },
  watch: {
    // 当工厂或地图变化时，存储变化
    factory: {
      deep: true,
      handler: 'storageFactoryData'
    }
  },
  computed: {
    enabledEdit() {
      return Boolean(!this.factory.id);
    },
    enabledPlay() {
      const curFactory = this.factory_list.find(factory => factory.id === this.factory.id);
      return !(curFactory && curFactory.children.length);
    }
  },
  components: {
    MapCascader,
    Device,
    DeviceInfo
  }
};
</script>

<style lang="scss" scoped>
@import '@/common/style/var';
@import './style/index.scss';
.map {
  width: 100vw;
  height: 100vh;
  background: #121212;
  position: relative;
}
.header {
  width: 100%;
  padding: 30px 30px 0 0;
  position: fixed;
  z-index: 1;
  .right {
    float: right;
    display: flex;
    .edit {
      margin: 0 16px;
      margin-right: 6px;
    }
  }
}
.main {
  height: 100%;
  overflow: hidden;
  .label {
    display: inline-block;
    color: rgb(136, 136, 136);
    border-radius: 3px;
    background: $secondary-color;
    position: fixed;
    top: 50%;
    left: -2px;
    cursor: pointer;
    z-index: 1;
    &:hover {
      color: $--color-primary;
    }
  }
  #graph {
    height: 100%;
  }
}
</style>
